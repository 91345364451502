.App {
  font-family: Arial, sans-serif;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.panel-page {
  background-color: #f0f2f5;
  min-height: 100vh;
}

.ant-card {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ant-card-body {
  padding: 20px;
}

.ant-btn {
  height: 40px;
  border-radius: 8px;
}

.ant-input {
  border-radius: 4px;
}

.ant-slider {
  margin-bottom: 20px;
}

.ant-list-item-meta-title {
  font-weight: bold;
}
