.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-select {
  width: 500px; /* Full width */
  max-width: 400px; /* Maximum width */
  padding: 10px; /* Padding for better touch targets */
  border: 1px solid #ced4da; /* Border color */
  border-radius: 0.25rem; /* Rounded corners */
  background-color: #f8f9fa; /* Light background */
  transition: border-color 0.2s ease-in-out; /* Transition for border on focus */
  margin-bottom: 30px;

}

.select-text{
  text-align: center;
}

.custom-select:focus {
  border-color: #80bdff; /* Change border color on focus */
  outline: 0; /* Remove outline */
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); /* Add a shadow effect */
}

.centered-row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.boxPlots{
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
  margin-left: -15vw;
  justify-content: space-evenly;
 
}

.boxPlotComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px solid black; */
}

.boxPlotComponent>p{
    margin-top: -40px;
    z-index: 1000
}

.promoter-score{
  margin-top: -100px;
  margin-bottom: 100px;
  font-size: 1.3rem;
}

.treemap-container {
   height: 100%;
   width: 100%;
  }

  .thematicCharts{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .themeBrand{
    margin-top: 50px;
text-align: center;

  }


  .treeChart{
    margin-top: -20px;

  }



  .threeCharts{
    border: 3px solid rgb(231, 231, 231);
    margin: 20px;
    border-radius: 30px;
    margin-bottom: 30px;
  }
  
  .transcript-container {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 1000px;
    max-width: 90vw;
    cursor: pointer;
  }
  
  .transcript-header {
    background-color: #f0f0f0;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .transcript-details {
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ccc;
  }

  .thematic-charts{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
   
    flex-wrap: wrap;
  }

  .themeCharts{
    border: 2px solid rgb(237, 237, 237);
  border-radius: 10px;
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 20px;
  }

  /* Container styling for modern look */
.report-container {
  background-color: #fefefe;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  position: relative;
  left: 100px;
}

/* Scroll progress indicator styling */
.scroll-progress {
  position: fixed;
  top: 0;
  right: 0;
  width: 5px; /* Thin but visible */
  background-color: #4a90e2;
  border-radius: 4px;
  z-index: 10;
}

/* Title styling */
.report-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-top: 50px;
}

/* Content styling */
.report-content {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.6;
  padding: 10px 30px;
  text-align: justify;
}

/* Individual report item styling */
.report-item {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  margin: 10px 0;
}

.report-item-title {
  font-size: 1.8rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.report-item-description {
  font-size: 1.1rem;
  color: #666;
  margin-top: 5px;
  white-space: pre-line;
  line-height: 1.2; /* Adjust to control the spacing between lines */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .report-title {
    font-size: 2rem;
  }

  .report-item-title {
    font-size: 1.5rem;
  }

  .report-item-description {
    font-size: 1rem;
  }
}

.sidebar {
  position: fixed;
  top: 5%;
  left: 20px;
  width: 240px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(112, 158, 124, 0.2);

}

.sidebar-icon {
  width: 100%;
  /* text-align: center; */
  margin-left: 20%;
  padding: 20px 10px;
  color: #000000;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.sidebar-icon:hover,
.sidebar-icon.active {
  color: #4f65c7;
  font-weight: bold;

}
.sidebar-icon:first-of-type {
margin-top: 50px;
}

.icon-label {
  /* display: block; */
  font-size: 15px;
  margin-top: 5px;
  margin-left: 10px;
}

.main-content {
  margin-left: 80px; /* Adjust based on sidebar width */
  padding: 20px;
}


  
